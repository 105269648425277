import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import AuthOrApp from './authOrApp'
import Dashboard from '../dashboard/dashboard';
import Usuario from '../usuario/usuario';
import UsuarioJogador from '../usuario/usuarioJogador';
import Esporte from '../esporte/esporte';
import Quadra from '../quadra/quadra';
import Professor from '../professor/professor';
import Agenda from '../agenda/agenda';
import Categoria from '../categorias/categoria';
import Competicao from '../competicao/competicao';
import CompeticaoCategoria from '../competicao/competicaoCategoria/competicaoCategoria';
import CompeticaoImpedimento from '../competicao/competicaoImpedimento/competicaoImpedimento'
import CompeticaoInscricao from '../competicao/competicaoInscricao/competicaoInscricao';
import Classificacao from '../classificacao/classificacao';

export default props => (
	<div id='id-content-wrapper' className='content-wrapper' >
		<Switch>
			<Route exact path='/' component={Agenda} />
			<Route path='/usuario' component={Usuario} />
			<Route path='/usuarioJogador' component={UsuarioJogador} />
			<Route path='/esporte' component={Esporte} />
			<Route path='/quadra' component={Quadra} />
			<Route path='/professor' component={Professor} />
			<Route path='/agenda' component={Agenda} />
			<Route path='/categoria' component={Categoria} />
			<Route path='/competicao' component={Competicao} />
			<Route path='/competicaoCategoria' component={CompeticaoCategoria} />
			<Route path='/competicaoImpedimento' component={CompeticaoImpedimento} />
			<Route path='/competicaoInscricao' component={CompeticaoInscricao} />
			<Route path='/classificacao' component={Classificacao} />
			<Redirect from='*' to='/' />
		</Switch>
	</div>
);
