const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	jogadorAtivo: true,
	lista: [],
	listaQuadra: [],
	lista: [],
	listaEsporte: [],
	listaProfessor: [],
	listaHoras: [],
	listaUsuario: [],
	listaHorariosAgendados: []
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
		case 'DASHBOARD_JOGADOR_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'DASHBOARD_JOGADOR_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'DASHBOARD_JOGADOR_ATIVO_LISTADO':
			return {
				...state,
				jogadorAtivo: action.payload.data[0] ? true : false
			};

        case 'DASHBOARD_JOGADOR_AGENDADO_LISTADO':
            return {
				...state,
				listaHorariosAgendados: action.payload.data
			};

		case 'DASHBOARD_JOGADOR_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

        case 'DASHBOARD_JOGADOR_ESPORTE_SELECT_LISTADO':
            return {
				...state,
				listaEsporte: action.payload.data
			};

        case 'DASHBOARD_JOGADOR_PROFESSOR_SELECT_LISTADO':
            return {
				...state,
				listaProfessor: action.payload.data
			};

        case 'DASHBOARD_JOGADOR_QUADRA_SELECT_LISTADO':
            return {
				...state,
				listaQuadra: action.payload.data
			};

		case 'DASHBOARD_JOGADOR_HORAS_SELECT_LISTADO':
            return {
				...state,
				listaHoras: action.payload
			};

		case 'DASHBOARD_JOGADOR_USUARIO_SELECT_LISTADO':
            return {
				...state,
				listaUsuario: action.payload.data
			};

        default:
            return state;
    }
}
