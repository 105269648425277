import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'AGENDA_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro,
				preco_unitario: FormatUtils.formatarValorTela(registro.preco_unitario, 2)
			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('agendaForm', {
			...registro,
			preco_unitario: FormatUtils.formatarValorTela(registro.preco_unitario, 2)
		})
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'AGENDA_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista(data) {
	const request = axios.get(`${consts.API_URL}/agenda?admin=true&data_agenda=${data ? data : DateFormat.formatarDataTelaParaParametro(DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()))}`);
    return {
        type: 'AGENDA_LISTADO',
        payload: request
    };
}

export function getListaNotificacao() {
    const request = axios.get(`${consts.API_URL}/agenda?visto=false`);
    return {
        type: 'AGENDA_NOTIFICACAO_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return async (dispatch, getState) => {
		if (!registro.id) {

			if (registro.cadastro_jogador) {
				await axios.post(`${consts.API_URL}/usuario`, {
					nome: registro.nome,
					email: registro.email,
					cpf: FormatUtils.removerMascara(registro.cpf),
					telefone: FormatUtils.removerMascara(registro.telefone),
					acesso_jogador: true,
					senha_definida: false,
					senha: 'padrao',
					senha_confirmacao: 'padrao'
				}).then(resp => {

					registro = {
						...registro,
						id_usuario_jogador: resp.data.id
					}

					dispatch(getListaUsuario());

				}).catch(e => {
					setErroAPI(e);
				});
			}

			if (registro.id_usuario_jogador) {
		        axios.post(`${consts.API_URL}/agenda`, {
					...registro,
					preco_unitario: FormatUtils.formatarValorBanco(registro.preco_unitario, 2),
					bloqueado: registro.bloqueado || false,
					id_usuario_inclusao: getState().auth.usuario.id,
					id_usuario_alteracao: getState().auth.usuario.id,
					visto: true
				}).then(resp => {
					setSuccess('Operação Realizada com sucesso.');
					dispatch(getLista());
					dispatch(setModoTela('lista'));
				}).catch(e => {
					setErroAPI(e);
				});
			}
		} else {
			axios.put(`${consts.API_URL}/agenda`, {
				...registro,
				preco_unitario: FormatUtils.formatarValorBanco(registro.preco_unitario, 2),
				bloqueado: registro.bloqueado || false,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function alterarStatus(registro) {
    return async (dispatch, getState) => {
		if (registro.id) {
			await axios.post(`${consts.API_URL}/agenda/alterarStatus`, registro).then(resp => {
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function alterarVisto(registro) {
    return async (dispatch, getState) => {
		if (registro.id) {
			await axios.post(`${consts.API_URL}/agenda/visto`, registro).then(resp => {
				dispatch(getListaNotificacao());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/agenda?id=${registro.id}&todos=${registro.todos ? true : false}&visto=true`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaHorariosAgendados(data) {
    const request = axios.get(`${consts.API_URL}/agenda/horarios?data_agenda=${data}`);
    return {
        type: 'AGENDA_AGENDADO_LISTADO',
        payload: request
    };
}

export function getListaEsporte() {
    const request = axios.get(`${consts.API_URL}/esporte/listarSelect`);
    return {
        type: 'AGENDA_ESPORTE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaProfessor() {
    const request = axios.get(`${consts.API_URL}/professor/listarSelect`);
    return {
        type: 'AGENDA_PROFESSOR_SELECT_LISTADO',
        payload: request
    };
}

export function getListaQuadra() {
    const request = axios.get(`${consts.API_URL}/quadra`);
    return {
        type: 'AGENDA_QUADRA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaHoras() {

	let data = new Date();
	data.setHours(0);
	data.setMinutes(0);

	let lista = [];

	for (var i = 0; i < 48; i++) {
		data.setMinutes(data.getMinutes() + 30);
		lista.push({
			id: `${data.getHours() < 10 ? '0' : ''}${data.getHours()}:${data.getMinutes() < 10 ? '0' : ''}${data.getMinutes()}`,
			valor: `${data.getHours() < 10 ? '0' : ''}${data.getHours()}:${data.getMinutes() < 10 ? '0' : ''}${data.getMinutes()}`
		});
	}

    return {
        type: 'AGENDA_HORAS_SELECT_LISTADO',
        payload: lista
    };
}

export function getListaUsuario() {

	let lista = [];
	return async dispatch => {
		await axios.get(`${consts.API_URL}/usuario/listarSelect?jogador=true`)
		.then(resp => {
			lista = resp.data;
		}).catch(e => {
			setErroAPI(e);
		});

		await axios.get(`${consts.API_URL}/usuario/listarSelect?usuario=true`)
		.then(resp => {
			for (var i = 0; i < resp.data.length; i++) {
				lista.push(resp.data[i]);
			}
		}).catch(e => {
			setErroAPI(e);
		});

		dispatch({
	        type: 'AGENDA_USUARIO_SELECT_LISTADO',
	        payload: {
				data: lista
			}
	    });
	};
}

export function gerarPdf(data, tipo) {

	return dispatch => {
		axios.get(`${consts.API_URL}/agenda/gerarPdf?data=${data}&tipo=${tipo}`)
		.then(resp => {
			window.open(`https://api-goldenfutebol.infinitum-ti.com/${resp.data}`, '_blank');
		}).catch(e => {
			setErroAPI(e);
		});
	};
}
