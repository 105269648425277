import React from 'react';
import Menu from './menu';
import imagemLogo from '../../assets/images/sologoazul.png';
import imagemNome from '../../assets/images/sonomeazul.png';

export default props => (
	<aside className="main-sidebar elevation-4 sidebar-light-indigo">
		<a href="../../" className="brand-link navbar-light">
			<img src={imagemLogo} alt="Golden Ball" className="brand-image" />
			<img className="brand-text font-weight-light" style={{ height: 30, paddingTop: 0 }} src={imagemNome} />
		</a>
		<div className="sidebar">
			<Menu />
		</div>
	</aside>
)
