import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import AuthReducer from '../auth/authReducer';
import DashboardReducer from '../dashboard/dashboardReducer';
import DashboardJogadorReducer from '../dashboardJogador/dashboardJogadorReducer';
import TabReducer from '../common/tab/tabReducer';
import UsuarioReducer from '../usuario/usuarioReducer';
import EsporteReducer from '../esporte/esporteReducer';
import QuadraReducer from '../quadra/quadraReducer';
import ProfessorReducer from '../professor/professorReducer'
import AgendaReducer from '../agenda/agendaReducer';
import CategoriaReducer from '../categorias/categoriaReducer';
import CompeticaoReducer from '../competicao/competicaoReducer';
import CompeticaoCategoriaReducer from '../competicao/competicaoCategoria/competicaoCategoriaReducer';
import CompeticaoImpedimentoReducer from '../competicao/competicaoImpedimento/competicaoImpedimentoReducer';
import CompeticaoInscricaoReducer from '../competicao/competicaoInscricao/competicaoInscricaoReducer'

const rootReducer = combineReducers({
	auth: AuthReducer,
    dashboard: DashboardReducer,
	dashboardJogador: DashboardJogadorReducer,
    usuario: UsuarioReducer,
	esporte: EsporteReducer,
	quadra: QuadraReducer,
	professor: ProfessorReducer,
	agenda: AgendaReducer,
	categoria: CategoriaReducer,
	competicao: CompeticaoReducer,
	competicaoCategoria: CompeticaoCategoriaReducer,
	competicaoImpedimento: CompeticaoImpedimentoReducer,
	competicaoInscricao: CompeticaoInscricaoReducer,
    tab: TabReducer,
    form: formReducer,
    toastr: toastrReducer
});

export default rootReducer;
