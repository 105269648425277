import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'USUARIO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('usuarioForm', registro)
    ];
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/usuario?usuario=true`);
    return {
        type: 'USUARIO_LISTADO',
        payload: request
    };
}

export function getListaJogador() {
    const request = axios.get(`${consts.API_URL}/usuario?jogador=true`);
    return {
        type: 'USUARIO_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/usuario`, {
				...registro,
				cpf: FormatUtils.removerMascara(registro.cpf),
				telefone: FormatUtils.removerMascara(registro.telefone)
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/usuario`, {
				...registro,
				cpf: FormatUtils.removerMascara(registro.cpf),
				telefone: FormatUtils.removerMascara(registro.telefone)
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function salvarJogador(registro) {
    return (dispatch, getState) => {
		axios.put(`${consts.API_URL}/usuario/jogador`, {
			...registro,
			cpf: FormatUtils.removerMascara(registro.cpf),
			telefone: FormatUtils.removerMascara(registro.telefone)
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaJogador());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/usuario?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluirJogador(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/usuario?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaJogador());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}


export function setPermissao(registro) {
    return (dispatch, getState) => {
		if (registro.id) {
	        axios.post(`${consts.API_URL}/usuario/permissao`, {
				...registro
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}
